import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SubCategoriesPopComponent } from './sub-categories-pop.component';




@NgModule({
  declarations: [SubCategoriesPopComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:[SubCategoriesPopComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class SubCategoriesPopModule { }
