import { Component, Input, OnInit} from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.page.html',
  styleUrls: ['./view-image.page.scss'],
})
export class ViewImagePage implements OnInit {
  @Input() imageDetail;
  @Input() senderName;

  constructor( public modalCtrl: ModalController,
    public iab:InAppBrowser,
    public platform:Platform,
    public datepipe: DatePipe) { 
    
  }

  ngOnInit() {
    // console.log("image", this.imageDetail);
  }

  downloadImage() {
    if (this.platform.is('android')) {
      const phone = this.iab.create(this.imageDetail.message, '_system');
      phone.show();
    } else {
      const phone = this.iab.create(this.imageDetail.message, '_blank');
      phone.show();
    }
  }

  back() {
    this.modalCtrl.dismiss();
  }
}
