import { Injectable } from '@angular/core';
import { LOOKUP_URL } from 'src/config';
import {HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PaymentRouteService {

  constructor(private httpClient: HttpClient) { }

  // lockdown upload Prescription
  razorpayAmtTransToDocAccViaPayment(routeObj){
    // console.log("route function===>", routeObj);
    var epurl=LOOKUP_URL+ '/scgetpaydetailposttodocaccsc';
    // console.log("url......"+epurl);
    let routeResponse:any = this.httpClient.post(epurl, routeObj, {responseType: "json"});
    // console.log("route api response===>", routeResponse);    
  }

}