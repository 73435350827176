import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-package-ques-ans-view',
  templateUrl: './package-ques-ans-view.page.html',
  styleUrls: ['./package-ques-ans-view.page.scss'],
})
export class PackageQuesAnsViewPage implements OnInit {

  @Input() ptqp:any;

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
