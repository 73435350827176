import { Injectable } from '@angular/core';
import AgoraRTC, { IAgoraRTCClient, LiveStreamingTranscodingConfig, ICameraVideoTrack, IMicrophoneAudioTrack, ScreenVideoTrackInitConfig, VideoEncoderConfiguration, AREAS, IRemoteAudioTrack, ClientRole } from "agora-rtc-sdk-ng";
import { BehaviorSubject } from 'rxjs';
import { WebsocketService } from '../services/websocket.service';
import { NavController, Platform } from "@ionic/angular";
import { SwasthStorageService } from '../storage/swasth-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RtcstreamService {

  IRtc :any= {
    // For the local client.
    client: null,
    // For the local audio and video tracks.
    localAudioTrack: null,
    localVideoTrack: null,
  };
  options = {
    appId: "9574c9a0ff9d41aa842ef6cc89e2904f",  // set your appid here
    //channel: "swasthtest123", // Set the channel name.
    // token: '', // Pass a token if your project enables the App Certificate.
    // uid: null
  };
  IUser:any=[];       // To add remote users in list
  updateUserInfo = new BehaviorSubject<any>(null); // to update remote users name
  remoteVideo=false;
  cams:any = [];
  currentCam:any;
  camLength:any;
  docuserID:any;
  ptuserID:any;
  circleCode:any;
  constructor(public webSocket: WebsocketService,
    private navCntrl: NavController,
    public swasthStorage: SwasthStorageService) {
    this.getCameraDevices();
   }
  createRTCClient() {
    this.IRtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
  }

  async getCameraDevices()
  {
  this.cams = await AgoraRTC.getCameras();
  console.log("Camera List",this.cams);
  this.currentCam = this.cams[0];
  this.camLength=this.cams.length;
  //alert(this.camLength);
  }

  async switchCamera()
  {
    //alert(this.cams[2].deviceId);
    if(this.camLength==2)
    {
      await this.IRtc.localVideoTrack.setDevice(this.cams[1].deviceId);
    }
    else if(this.camLength>2)
    {
      await this.IRtc.localVideoTrack.setDevice(this.cams[2].deviceId);
    }
    else
    {
      await this.IRtc.localVideoTrack.setDevice(this.cams[0].deviceId);
    }
  }

  async resetCamera()
  {
    await this.IRtc.localVideoTrack.setDevice(this.currentCam.deviceId);
  }

  muteAudio()
  {
    this.IRtc.localAudioTrack.setEnabled(false);
  }

  unmuteAudio()
  {
    this.IRtc.localAudioTrack.setEnabled(true);
  }

  muteVideo()
  {
    this.IRtc.localVideoTrack.setEnabled(false);
  }

  unmuteVideo()
  {
    this.IRtc.localVideoTrack.setEnabled(true);
  }


  // To join a call with tracks (video or audio)
  async localUser(token:any,uuid:any,channel:any) {
    console.log("App ID",this.options.appId);
    console.log("Channel",channel)
    console.log("Token",token);
    console.log("Uid",uuid);
    // alert(token);
    // alert(uuid);
    // alert(channel);
    const uid = await this.IRtc.client.join(this.options.appId,channel,token,uuid);
    // const uid = await this.IRtc.client.join(this.options.appId,"swasthcircle13734293","0069574c9a0ff9d41aa842ef6cc89e2904fIADZUcrm6Te1jsx47cV1j5gaqWNXQwLK35VmJSgK8Q88RHYkS4e3UIuOIgCb7RidLfcGZAQAAQAt9wZkAgAt9wZkAwAt9wZkBAAt9wZk","10780872");
    // Create an audio track from the audio sampled by a microphone.
    this.IRtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    // Create a video track from the video captured by a camera.
      this.IRtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: "360p",
        facingMode:"user"
      });
    

    // Publish the local audio and video tracks to the channel.
    this.IRtc.localVideoTrack.play("local-player");
    // channel for other users to subscribe to it.
    await this.IRtc.client.publish([this.IRtc.localAudioTrack, this.IRtc.localVideoTrack]);
  }

  agoraServerEvents(rtc:any) {

    rtc.client.on("user-published", async (user:any, mediaType:any) => {
      console.log(user, mediaType, 'user-published');

      await rtc.client.subscribe(user, mediaType);
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        remoteVideoTrack.play('remote-playerlist' + user.uid);
      }
      if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
    });
    rtc.client.on("user-unpublished", (user:any) => {
      console.log(user, 'user-unpublished');
    });


    rtc.client.on("user-joined", (user:any) => {
      console.log("User joined");
      //alert("User joined");
      this.remoteVideo=true;
      let id = user.uid;
      this.IUser.push({ 'uid': +id });
      this.updateUserInfo.next(id);
      console.log("user-joined", user, this.IUser, 'event1');
    });

    rtc.client.leave("user-left",()=>{
      console.log("User left");
      // this.leaveCall();
    });
  } 
  // To leave channel-()
  async leaveCall() {
    // Destroy the local audio and video tracks.
    this.IRtc.localAudioTrack.close();
    this.IRtc.localVideoTrack.close();
    // Traverse all remote users.
    this.IRtc.client.remoteUsers.forEach((user:any) => {
      // Destroy the dynamically created DIV container.
      const playerContainer = document.getElementById('remote-playerlist' + user.uid.toString());
      playerContainer && playerContainer.remove();
    });
    // Leave the channel.
    await this.IRtc.client.leave();
    // this.webSocket.sendVideoCallCutNotificationToDoctor(this.docuserID, this.ptuserID, this.circleCode, "patientrejectcall");
    // setTimeout(() => {
    //   this.swasthStorage.setStorage('videocall','yes');
      // for web
      // this.navCntrl.back();

      //for apk
      // this.router.navigateByUrl('/circle-list');
    // }, 500);

  }

  // rtc token
  async generateTokenAndUid(uid:any) {

    //let url = 'https://test-agora.herokuapp.com/access_token?&#39';
    //const opts = { params: new HttpParams({ fromString: "channel=test&uid=" + uid }) };
    //const data = await this.api.getRequest(url, opts.params).toPromise();
    return { 'uid': uid, token: '0069574c9a0ff9d41aa842ef6cc89e2904fIABfQQ6M2IakoMXqUhrLKP7W6ySf2DjMsOWi8Xb0Gb3e5MIdxRefoClKIgABiMX6D03mYwQAAQAPTeZjAgAPTeZjAwAPTeZjBAAPTeZj' }

  }

  generateUid() {
    const randomNo = Math.floor(Math.random() * 20000000) + 1;
    return randomNo;
  }

  
}
export interface IUser {
  uid: number;
  name?: string;
}
export interface IRtc {
  client: IAgoraRTCClient,
  localAudioTrack: IMicrophoneAudioTrack,
  localVideoTrack: ICameraVideoTrack
}