import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-view-covid-complaint',
  templateUrl: './view-covid-complaint.page.html',
  styleUrls: ['./view-covid-complaint.page.scss'],
})
export class ViewCovidComplaintPage implements OnInit {

  @Input() covidComplaint;

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
