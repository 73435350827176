import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-open-play-store',
  templateUrl: './open-play-store.page.html',
  styleUrls: ['./open-play-store.page.scss'],
})
export class OpenPlayStorePage implements OnInit {

  constructor(public modalCtrl: ModalController, 
    public iab: InAppBrowser, 
    public platform: Platform) { }

  ngOnInit() {
  }

  openPlayStore() {
    if (this.platform.is('android')) {
      const phone = this.iab.create('https://play.google.com/store/apps/details?id=patientcircle.swasth.online', '_system');
      phone.show();
    } else {
      const phone = this.iab.create('https://play.google.com/store/apps/details?id=patientcircle.swasth.online', '_blank');
      phone.show();
    }
  }

  modalDismissFn() {
    this.modalCtrl.dismiss();
  }

}
